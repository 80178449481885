<template>
    <div class="history">
        <div class="breadcrumb">
            <a @click.stop="goIdeaHome">我的创意</a>
            <i class="iconfont icon-arrow"></i>历史更新
        </div>
        <CompHeader title="历史更新"></CompHeader>
        <div class="idea_info" @click.stop="goIdea(idea_id)">
            <div class="cover">
                <img :src="$imgUrlFilter(ideaItem.logo, 100)" />
                <p>创意发布</p>
            </div>
            <div class="info">
                <p class="title">{{ideaItem.title}}</p>
                <p class="tips">
                    <span>类型：{{ideaItem.category}}</span>
                    <span>发起时间：{{ideaItem.create_time}}</span>
                    <span>创意ID：{{idea_id}}</span>
                </p>
                <!-- <el-button @click.stop="codeVisible = true">创意预览</el-button> -->
            </div>
        </div>
        <div v-if="isLoad">
            <template v-if="idealist && idealist.length">
                <el-table
                    :data="idealist"
                    style="width: 100%"
                    >
                    <el-table-column label="更新" width="258">
                        <template #default="scope">
                            <div class="proInfo">
                                <div v-if="scope.row.cover" class="cover">
                                    <el-image :src="$imgUrlFilter(scope.row.cover, 64)" fit="cover">
                                        <template #error>
                                        <img
                                            src="https://s.moimg.net/m/img/placeholder/headPic.png"
                                        />
                                        </template>
                                    </el-image>
                                </div>
                                <div class="proRight">
                                    <p class="title"><span v-if="scope.row.status == 1">「草稿」</span>{{ scope.row.title }}</p>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="审核状态">
                        <template #default="scope">
                            <el-popover
                                v-if="scope.row.status == 0 || scope.row.status == 31 || scope.row.status == 30"
                                placement="top-start"
                                effect="dark"
                                trigger="hover"
                                :content="scope.row.audit_reason"
                            >
                                <template #reference>
                                    <el-tag type="danger">{{scope.row.status == 30 ? '审核拒绝' : '未通过'}}<i class="icon-info2"></i></el-tag>
                                </template>
                            </el-popover>
                            <el-tag v-else-if="scope.row.status == 10" type="info">审核中</el-tag>
                            <p v-else>——</p>
                        </template>
                    </el-table-column>
                    <el-table-column label="置顶状态">
                        <template #default="scope">
                            <el-tag v-if="scope.row.is_top == 1" type="success">已置顶</el-tag>
                            <p v-else>——</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="168"
                        prop="create_time"
                        label="更新时间"
                    />
                    <el-table-column label="操作">
                        <template #default="scope">
                            <el-link v-if="scope.row.status == 0" @click.stop="audit(scope.row.audit_request_id)" :underline="false">人工审核</el-link>
                            <template v-if="scope.row.status == 30 || scope.row.status == 10">
                                <el-link @click.stop="delIdea(scope.row.update_id)" :underline="false">删除</el-link>
                            </template>
                            <template v-else>
                                <el-link v-if="!(scope.row.status == 30 || scope.row.status == 31 || scope.row.status == 0 || scope.row.status == 10 || scope.row.status == 1)" @click.stop="topIdea(scope.row.update_id,scope.row.is_top)" :underline="false">{{scope.row.is_top == 1 ? '取消置顶' : '置顶'}}</el-link>
                                <el-link :underline="false" @click.stop="edit(scope.row.idea_id,scope.row.update_id)"
                                    >编辑</el-link
                                >
                                <el-link @click.stop="delIdea(scope.row.update_id)" :underline="false">删除</el-link>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
                <CompPagination :total="total" :pageSize="page_size" @change="pageChange"/>
            </template>
            <CompError
                v-else
                class="cont-nothing-box"
                message="暂无更新"
                />
        </div>
    </div>
    <IdeaCode :url="ideaUpdateQrcodeUrl" :codeVisible="codeVisible" @close="codeVisible = false" />
</template>

<script>
import domain from '@/service/domain'
import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
import CompHeader from "@@/common/header.vue"
import CompPagination from "@@/common/pagination.vue"
import CompError from "@@/common/error.vue"
import IdeaCode from "@@/user/ideaCode.vue"

import { ideaUpdateList, topIdeaUpdate, delIdeaUpdate, ideaUpdateManualAudit } from "@api/index"
export default {
    components: {
        CompHeader,
        CompPagination,
        CompError,
        IdeaCode
    },
    computed: {
        ideaItem(){
            return JSON.parse(localStorage.getItem('ideaItem'))
        },
        ideaUpdateQrcodeUrl(){
            let url = 'https://m.modian.com/idea/'+this.$route.query.idea_id;
            if(/test/.test(location.host)){
                url = 'https://testm.modianinc.com/idea/'+this.$route.query.idea_id;
            }
            return url;
        },
    },
    data() {
        return {
            idea_id:this.$route.query.idea_id,
            isLoad: false,
            category_list:[],
            idealist: [],
            page: 1,
            page_size: 10,
            total: 0,
            codeVisible:false
        }
    },
    async created() {
        await this.getList()
    },
    provide() {
        return {
            refresh: this.getList
        }
    },
    methods: {
        async getList() {
            const loadingInstance2 = ElLoading.service({ fullscreen: true })
            const { status, data, message } = await ideaUpdateList({
                idea_id:this.idea_id,
                page: this.page,
                page_size: this.page_size,
            })
            loadingInstance2.close()
            if (status == 0 && data) {
                this.total = data.total
                this.idealist = [];
                data.list.forEach(ele => {
                    if(ele.mix_content){
                        for(let i = 0,j = ele.mix_content.length; i < j; i++){
                            if(!ele.cover){
                                if(ele.mix_content[i].content_type == 2){
                                    ele.cover = ele.mix_content[i].content_value
                                }
                                
                            }
                            if(!ele.cover){
                                if(ele.mix_content[i].content_type == 3){
                                    ele.cover = ele.mix_content[i].video_cover
                                }
                            }
                            if(!ele.title){
                                if(ele.mix_content[i].content_type == 1){
                                    ele.title = ele.mix_content[i].content_value
                                }
                            }
                            if(!ele.title){
                                let vote_length = ele.mix_content.filter(obj => obj.content_type == 4).length;
                                if(vote_length == ele.mix_content.length){
                                    ele.title = '[投票]'
                                }
                            }
                            if(!ele.title){
                                let vote_length = ele.mix_content.filter(obj => obj.content_type == 5).length;
                                if(vote_length == ele.mix_content.length){
                                    ele.title = '[抽奖]'
                                }
                            }
                            if(ele.cover && ele.title){
                                // console.log('循环'+i)
                                break; 
                            }
                        }
                    }
                    this.idealist.push(ele)
                });
                this.isLoad = true
            }else if(status == 10000){
                this.goLogin(message)
            }else{
                this.isLoad = true
                ElMessageBox.alert(message, '提示', {
                    confirmButtonText: '确定'
                })
            }
        },
        // 创意更新申请人工复审
        async audit(id) {
            const loadingInstance1 = ElLoading.service({ fullscreen: true })
            const { status,message } = await ideaUpdateManualAudit({
                request_id:id
            })
            loadingInstance1.close()
            if (status == 0) {
                ElMessage({
                    showClose: true,
                    message: "操作成功",
                    type: "success",
                });
                this.getList();
            }else if(status == 10000){
                this.goLogin(message)
            }else{
                ElMessageBox.alert(message, '提示', {
                    confirmButtonText: '确定'
                })
            }
        },
        // 创意更新置顶操作
        async topIdea(id,is_top) {
            const { status,message } = await topIdeaUpdate({
                update_id:id,
                is_top:is_top == 1 ? 0 : 1
            })
            if (status == 0) {
                ElMessage({
                    showClose: true,
                    message: "操作成功",
                    type: "success",
                });
                this.getList();
            }else if(status == 10000){
                this.goLogin(message)
            }else{
                ElMessageBox.alert(message, '提示', {
                    confirmButtonText: '确定'
                })
            }
        },
        // 删除创意更新
        async delIdea(id) {
            ElMessageBox.confirm(
                '是否确认删除',
                '提示',
                {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消'
                }
            )
            .then(async () => {
                const { status,message } = await delIdeaUpdate({
                    update_id:id,
                })
                if (status == 0) {
                    ElMessage({
                        showClose: true,
                        message: "操作成功",
                        type: "success",
                    });
                    this.getList();
                }else if(status == 10000){
                    this.goLogin(message)
                }else{
                    ElMessageBox.alert(message, '提示', {
                        confirmButtonText: '确定'
                    })
                }
            })
            .catch(() => {
            
            })
        },
        edit(idea_id,update_id){
            window.location.href = domain.member + '/initiate/idea_update?idea_id='+idea_id+'&update_id='+update_id;
        },
        pageChange(val) {
            this.page= val
            this.getList()
        },
        goIdeaHome(){
            this.$router.push({
                name: 'create_idea'
            });
        },
        goIdea(id){
            let newTab = window.open('about:blank');
            newTab.location.href = domain.wap+'/idea/'+id;
        },
        goLogin(message) {
            ElMessageBox.confirm(
                message,
                '提示',
                {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消'
                }
            )
            .then(() => {
                location.href = domain.account
            })
            .catch(() => {
            
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    .history{
        padding: 40px 0;
        margin: 0 auto;
        width: 800px;
        p{
            margin: 0;
        }
        .breadcrumb{
            display: flex;
            align-items: center;
            font-weight: 500;
            color: #222C37;
            padding: 0 0 40px 0;
            font-size: 16px;
            line-height: 16px;
            a{
                cursor: pointer;
                color: #7F7F7F;
                font-weight: 400;
                &:hover{
                    color: #222C37;
                }
            }
            i{
                padding: 0 10px;
                color: #7F7F7F;
            }
        }
        .idea_info{
            padding: 40px 0;
            display: flex;
            align-items: center;
            cursor: pointer;
            .cover{
                position: relative;
                overflow: hidden;
                border-radius: 4px;
                width: 100px;
                height: 100px;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                &::after{
                    content:'';
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100px;
                    height: 50px;
                    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
                    z-index: 1;
                }
                p{
                    position: absolute;
                    left: 0;
                    bottom: 10px;
                    z-index: 2;
                    width: 100px;
                    text-align: center;
                    font-size: 16px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 16px;
                }
            }
            .info{
                margin: 0 0 0 20px;
                .title{
                    font-size: 18px;
                    font-weight: 600;
                    color: #1A1A1A;
                    line-height: 18px;
                }
                .tips{
                    padding: 14px 0;
                    font-size: 14px;
                    font-weight: 400;
                    color: #7F7F7F;
                    line-height: 14px;
                    span{
                        display: inline-block;
                        height: 14px;
                        line-height: 14px;
                        padding: 0 30px 0 0;
                    }
                }
            }
        }
        .el-table{
            margin: 0 0 20px 0;
            .el-tag{
                cursor: default;
                ::v-deep .el-tag__content{
                    cursor: default;
                    display: flex;
                    align-items: center;
                    i{
                        padding-left: 4px;
                    }
                }
            }
            .proRight{
                .title{
                    display: -webkit-box;
                    overflow: hidden;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    white-space: normal;
                    word-wrap: break-word;
                    span{
                        font-size: 14px;
                        color: #F5A728;
                    }
                }
            }
        }
        .cover{
            ::v-deep .el-image{
                width: 100%;
                height: 100%;
            }
        }
    }
    .cont-nothing-box{
        padding: 120px 0 0 0;
    }
</style>
