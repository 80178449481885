<template>
    <div v-show="codeVisible" class="dialog">
        <div class="content">
            <div class="title">
                创意预览
                <i class="icon-fork" @click.stop="close"></i>
            </div>
            <div class="qrcode">
                <CompQrcode :id="'QrUpdateCode'" :text="url" :width="160" :height="160" />
            </div>
            <p>微信扫码查看“创意详情”</p>
        </div>
    </div>
</template>

<script>
import CompQrcode from '@@/compose/qrcode.vue'
export default {
    props: {
        url: {
            type: String,
        },
        codeVisible: {
            type: Boolean,
            default: false
        },
    },
    components: {
        CompQrcode
    },
    mounted(){
        
    },
    methods: {
        close() {
            this.$emit("close");
        }
    }
}
</script>

<style lang="scss" scoped>
.dialog{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(26, 26, 26, .4);
    z-index:9999;
    .content{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        width: 420px;
        height: 290px;
        background: #FFFFFF;
        border-radius: 4px;
        .title{
            margin: 0 30px;
            padding: 20px 0;
            position: relative;
            height: 60px;
            width: 360px;
            font-size: 20px;
            font-weight: 600;
            color: #1A1A1A;
            line-height: 20px;
            border-bottom: 1px solid #E8E8E8;
            i{
                cursor: pointer;
                position: absolute;
                top: 10px;
                right: -20px;
                color:  #7F7F7F;
                &:hover{
                    color: #00C4A1;
                }
            }
        }
        .qrcode{
            margin: 20px auto 7px;
            width: 160px;
            height: 160px;
        }
        >p{
            margin: 0;
            font-size: 16px;
            text-align: center;
            font-weight: 400;
            color: #7F7F7F;
            line-height: 24px;
        }
    }
}
</style>
